<template>
  <svg
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd" d="M12.0073 23.3201V16.4086C12.0073 8.9172 17.941 2.82225 25.3688 2.56326V2.55469H25.8613C33.5175 2.55469 39.7152 8.75235 39.7152 16.4086V23.3201H34.916V16.5249C34.916 11.5174 30.8688 7.47016 25.8613 7.47016C20.8542 7.47016 16.8065 11.5287 16.8065 16.5249V23.3201H12.0073ZM6.10449 50.5547V23.3202H45.6168V50.5547H6.10449ZM28.6109 44.3798V38.5073H28.5993C29.797 37.6584 30.5761 36.2746 30.5761 34.6931C30.5761 32.0882 28.4597 29.9718 25.8549 29.9718C23.25 29.9718 21.1336 32.0882 21.1336 34.6931C21.1336 36.263 21.9243 37.6352 23.1105 38.4841V44.3798H28.6109Z"
        fill="inherit"
      />
    </g>
  </svg>
</template>
