<template>
  <svg
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="inherit"
        d="M49.5 1.9292L46.8242 21.4014L46.782 21.4337L46.787 21.4387L28.0623 40.1633L11.2658 23.3668L29.9904 4.64223L29.9954 4.6472L30.0277 4.60498L49.5 1.9292ZM34.539 16.8901C36.0845 18.4356 38.5901 18.4356 40.1356 16.8901C41.681 15.3447 41.681 12.8391 40.1356 11.2936C38.5901 9.74817 36.0845 9.74817 34.539 11.2936C32.9936 12.8391 32.9936 15.3447 34.539 16.8901ZM31.9354 23.1898C30.9148 24.2104 29.26 24.2104 28.2393 23.1898C27.2187 22.1691 27.2187 20.5144 28.2393 19.4937C29.26 18.4731 30.9148 18.4731 31.9354 19.4937C32.9561 20.5144 32.9561 22.1691 31.9354 23.1898ZM13.7114 8.71912L21.5051 8.71548L9.29368 20.9382L1.5 20.9419L13.7114 8.71912ZM42.7136 29.9242L42.71 37.7178L30.4872 49.9292L30.4908 42.1356L42.7136 29.9242ZM19.0391 35.1885L16.2408 32.3902L4.95869 43.6723L7.75698 46.4705L19.0391 35.1885ZM20.4387 36.5876L23.237 39.3859L15.315 47.3079L12.5167 44.5096L20.4387 36.5876ZM14.8411 30.9908L12.0429 28.1926L4.12081 36.1146L6.91909 38.9129L14.8411 30.9908Z"
      />
    </g>
  </svg>
</template>
